<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>配件类型</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-button type="primary" @click="opentan()">添加信息</el-button>

      <el-table :data="options" style="width: 100%" :row-style="{ height: '50px' }" row-key="id" border lazy :tree-props="{ children: 'list', hasChildren: 'hasChildren' }">
        <el-table-column prop="title" label="货物标题" width="500"></el-table-column>
        <el-table-column prop="tag" label="唯一标识" width="300"></el-table-column>
        <el-table-column prop="address" label="操作" width="169">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="货物类型" :visible.sync="dialogFormVisible" :before-close="handleClose" width="40%">
        <el-form :model="form" ref="form" label-width="100px" :rules="rules">
          <el-form-item label="货物标题" label-width="150px" prop="title">
            <div class="el-select"><el-input v-model="form.title" placeholder="请输入货物标题"></el-input></div>
          </el-form-item>
          <el-form-item label="唯一标识" prop="tag" label-width="150px">
            <div class="el-select"><el-input v-model="form.tag" oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')" placeholder="内容只能是2位字母"></el-input></div>
          </el-form-item>
          <div class="tishi">不选默认为顶级</div>
          <el-form-item label="上级归属" label-width="150px">
            <el-tree
              default-expand-all
              :default-checked-keys="defaultList"
              :data="options"
              ref="tree"
              :props="defaultProps"
              node-key="id"
              show-checkbox
              check-strictly
              @check-change="handleNodeClick"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="quanxiao()">取 消</el-button>
          <el-button type="primary" @keyup.enter.native="quanque()" @click="quanque(tantype)">确定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { Xinxing, Endhuoshu, Huoshu } from '../../../api/store'
export default {
  data() {
    return {
      options: [],
      tantype: 'add',
      dialogFormVisible: false,
      form: {
        title: '',
        parent_id: 0,
        tag: '',
        id: ''
      },
      defaultList: [],
      defaultProps: {
        children: 'list',
        label: 'title'
      },
      rules: {
        title: [
          { required: true, message: '请输入货物标题', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ],
        tag: [
          { required: true, message: '请输入唯一标识', trigger: 'blur' },
          { min: 2, max: 2, message: '内容只能为两位字母', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.Huowushu()
  },
  methods: {
    // 编辑
    editfun(ev) {
      console.log(ev)
      this.tantype = 'edit'
      this.form = JSON.parse(JSON.stringify(ev))
      this.form.title = ev.title
      this.form.parent_id = ev.parent_id
      this.form.tag = ev.tag
      this.form.id = ev.id
      this.opentan()
      this.defaultList = [ev.parent_id]
    },
    handleNodeClick(data, checked) {
      // console.log(data, checked)
      if (checked) {
        this.$refs.tree.setCheckedNodes([data])
        this.form.parent_id = data.id
      } else {
        // this.form.tag = ''
      }
    },
    opentan() {
      this.dialogFormVisible = true
      this.Huowushu()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false
      this.quanxiao()
    },
    // 添加取消
    quanxiao() {
      this.form.title = ''
      this.form.parent_id = 0
      this.form.tag = ''
      this.$refs.tree.setCheckedKeys([])
      this.dialogFormVisible = false
      this.form.list = []
      this.form.id = ''
      this.form.level = ''
      this.tantype = 'add'
    },
    // 确定添加
    quanque(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['form'].validate(v => {
            if (v) {
              this.ClientInformations(this.form)
            }
          })
          break
        case 'edit':
          this.$refs['form'].validate(v => {
            if (v) {
              this.editcompanyfun(this.form)
            }
          })
          console.log(type)
          break
      }
    },

    // 新建类型
    async ClientInformations(v) {
      const { data } = await Xinxing(v)
      this.getanpostfun(data, '添加', this.Huowushu(), this.quanxiao())
    },
    async Huowushu() {
      const { data } = await Huoshu()
      // console.log(data)
      this.options = data.data
    },
    // 修树信息
    async editcompanyfun(v) {
      const { data } = await Endhuoshu(v)
      this.getanpostfun(data, '修改', this.quanxiao(), this.Huowushu())
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
.tishi {
  margin: 0 auto;
  margin-left: 80px;
  color: red;
}
</style>